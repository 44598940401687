import { useState } from 'react'
import { ReactComponent as Hamburger } from '../assets/icons/hamburger-menu.svg'
import { ReactComponent as Close } from '../assets/icons/close.svg'
import './Navbar.css'

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
        <img
            src="https://www.itronix.io/static/media/logo.076d8a7431c3ed9f1ded.png"
            alt="OpenAI Logo"
        />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
            <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
        <div className="close-icon" onClick={handleShowNavbar}>
            <Close />
        </div>
          <ul>
            <li>
              <a href="https://www.itronix.io/">Home</a>
            </li>
            <li>
              <a href="https://www.itronix.io/#features">Features</a>
            </li>
            <li>
              <a href="https://www.itronix.io/#contact">Contact</a>
            </li>

            <li className='navigation_items_button'>
              <a href="https://www.itronix.io/#swap">Swap</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar