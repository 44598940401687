import React, { useState, useEffect } from 'react';

const ButtonComponent = ({ onSuccess }) => {
  // State to track the number of times the button has been clicked
  const [clickCount, setClickCount] = useState(0);

  // Function to handle button click
  const handleClick = () => {
    // Check if the user has clicked the button less than twice
    if (clickCount < 2) {
      // Increment the click count and update the cookie
      setClickCount(prevCount => prevCount + 1);
      document.cookie = `clickCount=${clickCount + 1}; max-age=31536000`; // Set cookie to last for a year
      // Get user's IP address
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          const userIP = data.ip;
          // Check if the user's IP address has a cookie
          const ipCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(`ip=${userIP}`));
          if (!ipCookie) {
            // If no cookie for this IP address, create one to count requests
            document.cookie = `ip=${userIP}; max-age=31536000`; // Set cookie to last for a year
          }
        });
      // Call the onSuccess callback with true
      onSuccess(true);
    } else {
      // Call the onSuccess callback with false
      onSuccess(false);
    }
  };

  // Check the click count when the component mounts
  useEffect(() => {
    // Parse the click count from the cookie
    const cookies = document.cookie.split(';');
    const clickCountCookie = cookies.find(cookie => cookie.trim().startsWith('clickCount='));
    if (clickCountCookie) {
      const count = parseInt(clickCountCookie.split('=')[1]);
      if (!isNaN(count)) {
        setClickCount(count);
      }
    }
  }, []);

  return (
    <button className="find-button" onClick={handleClick}>Generate an Image</button>
  );
};

export default ButtonComponent;
