import React, { useState } from "react";
import { Configuration, OpenAIApi } from "openai";
import ButtonComponent from './Components/ButtonComponent';
import NavigationBar from "./Components/NavigationBar";

const App = () => {
  const OpenAIApiKey = process.env.REACT_APP_API_KEY;
  const [prompt, setprompt] = useState("");
  const [result, setresult] = useState("");
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  // State to track whether the button has been clicked
  const [buttonSearched, setButtonSearched] = useState(false);
  const [placeholder, setPlaceholder] = useState(
    "Search Bears with Paint Brushes the Starry Night, painted by Vincent Van Gogh.."
  );

  const configuration = new Configuration({
    apiKey: OpenAIApiKey,
  });

  const openai = new OpenAIApi(configuration);

  const generateImage = async(isSuccess) => {
    if (isSuccess) {
    setPlaceholder(`Search ${prompt}..`);
    setloading(true);
    setButtonClicked(true);
      console.log("User is allowed to search");
      try {
        const res = await openai.createImage({
          prompt: prompt,
          n: 1,
          size: "512x512",
        });
        setloading(false);
        if (
          res.data &&
          res.data.data &&
          res.data.data[0] &&
          res.data.data[0].url
        ) {
          setresult(res.data.data[0].url);
        } else {
          console.error("Invalid response format from OpenAI API");
        }
      } catch (error) {
        console.error("Error generating image:", error);
        setloading(false);
        seterror("Error generating image. Please try again later.");
      }
    } else {
      alert('You are not allowed to search again.')
    }
  };

  return (
    <>
      <NavigationBar />  
      {loading ? (
        <>
          <h2>Generating..Please Wait..</h2>
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </>
      ) : (
        <>
          <div className="banner">
          <h2 className="title">Generate an Image using AI</h2>
          <input
            className="app-input"
            placeholder={placeholder}
            onChange={(e) => setprompt(e.target.value)}
            rows="10"
            cols="40"
          />
          <ButtonComponent
            className="find-button"
            onSuccess={generateImage}
          >
            Generate an Image
          </ButtonComponent>
          </div>
          {result.length > 0 ? (
            <img className="result-image" src={result} alt="result" />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default App;
